import Vue from 'vue';
import Component from 'vue-class-component';
import { VueReCaptcha } from 'vue-recaptcha-v3'

export interface RecaptchaResponse {
    RecaptchaResponse?: string
}

@Component
export default class RecaptchaMixin extends Vue {
    siteKey: string = '';
    mounted() {
        Vue.use(VueReCaptcha, { 
            siteKey: this.siteKey,
            loaderOptions: {
                explicitRenderParameters: {
                    badge: 'bottomleft'
                }
            }
        })
        this.showCaptcha()
    }
    hideCaptcha() {
        document.getElementById('recaptcha-visible').remove()
    }
    showCaptcha(){
        document.head.insertAdjacentHTML('beforeend', '<style id="recaptcha-visible">.grecaptcha-badge{visibility:visible}</style>')
    }
    async getRecaptchaToken() {
        await this.$recaptchaLoaded()
        return await this.$recaptcha('submit')
    }
}
