import { Component, Mixins, Prop } from 'vue-property-decorator';

import CheckBoxRow from '@/forms/FormElements/Rows/CheckBoxRow/CheckBoxRow.vue';
import DebugModel from '@/forms/FormElements/Common/DebugModel/DebugModel.vue';
import DropdownRow from '@/forms/FormElements/Rows/DropdownRow/DropdownRow.vue';
import ButtonRow from '@/forms/FormElements/Rows/ButtonRow/ButtonRow.vue';

import { EmailSignUpUser, names, SelectItemAge } from './EmailSignUpFollowUpForm.types';
import SelectItem from '@/common/interfaces/SelectItem';
import Message from '@/forms/Validations/Message';
import getValidation from '@/modules/EmailSignUpModule/EmailSignUpFollowUpForm/EmailSignUpFollowUpForm.validations';
import { CtaButtonStyle } from '@/common/interfaces/CtaLinkItem';

import FormValidationMixin from '@/forms/FormElements/Mixins/FormValidationMixin';

const { validations, model, messages } = getValidation();

@Component({
  validations,
  components: {
    DebugModel,
    CheckBoxRow,
    DropdownRow,
    ButtonRow,
  },
})
export default class EmailSignUpFollowUpForm extends Mixins(FormValidationMixin) {
  names = names;

  model: EmailSignUpUser = model;

  messages: Message[] = messages;

  buttonStylePrimaryDarkBlack = CtaButtonStyle.PrimaryDarkBlack;

  tellAboutYourSelfList: SelectItem[] = [
    { Text: 'Bird Watching', Value: 'Bird Watching' },
    { Text: 'Cultural Travel', Value: 'Cultural Travel' },
    { Text: 'Gourmet Dining', Value: 'Gourmet Dining' },
    { Text: 'Kayaking', Value: 'Kayaking' },
    { Text: 'Photography', Value: 'Photography' },
    { Text: 'Scuba/Snorkeling/Swimming', Value: 'Scuba/Snorkeling/Swimming' },
    { Text: 'Walking/Hiking', Value: 'Walking/Hiking' },
    { Text: 'Whale Watching', Value: 'Whale Watching' },
    { Text: 'Wildlife Observation', Value: 'Wildlife Observation' },
  ];
  @Prop()
  readonly HowDidYouHearAboutUs!: SelectItem[];
  @Prop()
  readonly IsFollowUpSubmitted!: string;

  WhatIsYourAgeGroup: SelectItemAge[] = [
    { Text: '18 & under', Value: '1', isAge: true },
    { Text: '19 - 45', Value: '2', isAge: true },
    { Text: '46 - 54', Value: '3', isAge: true },
    { Text: '55 - 64', Value: '4', isAge: true },
    { Text: '65 - 79', Value: '5', isAge: true },
    { Text: '80+', Value: '6', isAge: true },
  ];

  GenerallyDoYouTravel: SelectItem[] = [
    { Text: 'Travel Solo', Value: '1' },
    { Text: 'Travel with Companion', Value: '2' },
    { Text: 'Travel with Family', Value: '3' },
  ];

  fullOption(option: SelectItemAge) {
    if (option.Value === model.HowDidYouHearAboutUs) {
      model.ReferralSource = option.Text;
    }
    else if (option.isAge && option.Value === model.WhatIsYourAgeGroup) {
      model.AgeGroup = option.Text;
    }
    else if (option.Value === model.GenerallyDoYouTravel) {
      model.TravelPreference = option.Text;
    }
  }
}
