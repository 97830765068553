import { names as personNames } from '@/common/interfaces/dto/Person';
import SelectItem from '@/common/interfaces/SelectItem';
import { RecaptchaResponse } from '@/forms/FormElements/Mixins/RecaptchaMixin';

export interface EmailSignUpUser extends RecaptchaResponse {
  TopTravelInterests: string[];
  HowDidYouHearAboutUs: string;
  WhatIsYourAgeGroup: string;
  GenerallyDoYouTravel: string;

  ReferralSource?: string;
  AgeGroup?: string;
  TravelPreference?: string;
  AnalyticsPayload?: string;
}

export interface SelectItemAge extends SelectItem {
  isAge?: Boolean;
}

const formNames = {
  TermsAndConditions: 'TermsAndConditions',

  TopTravelInterests: 'TopTravelInterests',

  HowDidYouHearAboutUs: 'HowDidYouHearAboutUs',
  WhatIsYourAgeGroup: 'WhatIsYourAgeGroup',
  GenerallyDoYouTravel: 'GenerallyDoYouTravel',
};

export const names = {
  ...personNames,
  ...formNames,
};
