import { EmailSignUpUser } from '@/modules/EmailSignUpModule/EmailSignUpForm/EmailSignUpForm.types';
import ApiService from '@/common/ApiService/ApiService';
import { HttpVerb } from '@/common/ApiService/HttpVerb';
import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { isInUSTimezone } from 'in-us'

const EmailSignUpService = new ApiService('/api/EmailSignUpService/', {
  showLoading: true,
});

export const submitEmailSignUp = (model: EmailSignUpUser): Promise<any> => {
  return EmailSignUpService.call('EmailSignUp', model, {
    verb: HttpVerb.POST,
    returnData: false,
  }).then((res: AxiosResponse) => {
    if (res.status == 200) {
      Cookies.set('newsletterSubscribed', true, { expires: 365 });
   
      const hasAccepted = hasAcceptedAdditionalIntegrationsCookie()
      if (hasAccepted === true || (typeof hasAccepted === 'undefined' && isInUSTimezone())) {
        const script = document.createElement('script')
        script.src = `//cdn.datasteam.io/js/D2CA27E6C1BB02.js?conversion=true`
        document.head.appendChild(script)
      }

      return { IsOk: true, Item: res.data, Error: null };
    }
    return { IsOk: false, Item: null, Error: res.data };
  });
};

export const submitEmailSignUpFollowUp = (model: EmailSignUpUser): Promise<any> => {
  return EmailSignUpService.call('UpdateEmailSignUp', model, {
    verb: HttpVerb.PATCH,
    returnData: false,
  }).then((res: AxiosResponse) => {
    if (res.status == 200) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return { IsOk: true, Item: res.data, Error: null };
    }
    return { IsOk: false, Item: null, Error: res.data };
  });
};

export const hasAcceptedAdditionalIntegrationsCookie = (): boolean => {
  const cookies = Cookies.get('tracking-preferences') || Cookies.get('tracking-preferences-stg')
  if (!cookies) return undefined
  const preferences = JSON.parse(cookies)
  return preferences.custom?.['Additional Integrations'] || false
}
