import Vue from 'vue';
import Component from 'vue-class-component';
import { DispatchOptions, CommitOptions } from 'vuex';

export function getStoreModuleState(instance: Vue) {
  return instance.$store.state[getStoreModulePath(instance)];
}

export function getStoreModulePath(instance: Vue) {
  // @ts-ignore
  if (instance.$route?.params?.store) return instance.$route.params.store;

  if (instance.$attrs.vuexModuleStore) return instance.$attrs.vuexModuleStore;

  let options: any = instance.$options;
  let parent = instance.$parent;
  while (parent && !options.modulePath && !parent.$attrs.vuexModuleStore) {
    options = parent.$options;
    parent = parent.$parent;
  }

  return options.modulePath || parent?.$attrs.vuexModuleStore;
}

@Component({})
export default class StoreModuleMixin extends Vue {
  getters(getter: string) {
    return this.$store.getters[`${this.$storeModulePath}/${getter}`];
  }

  getAction(action: string) {
    return (payload?: any, options?: DispatchOptions) => {
      return this.$store.dispatch(`${this.$storeModulePath}/${action}`, payload, options);
    };
  }

  getMutation(mutation: string) {
    return (payload?: any, options?: CommitOptions) => {
      this.$store.commit(`${this.$storeModulePath}/${mutation}`, payload, options);
    };
  }

  get state() {
    return this.$store.state[this.$storeModulePath];
  }

  get $storeModulePath() {
    const path = getStoreModulePath(this);

    return path;
  }
}
