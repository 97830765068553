import {Module, ActionTree, MutationTree, Store} from 'vuex';
import { RootState } from '@/store/types';
import { registerSubModule } from '@/utils/vueUtils';

import { EmailSignUpViewModel } from '@/modules/EmailSignUpModule/EmailSignUpModule.types';
import { EmailSignUpUser } from '@/modules/EmailSignUpModule/EmailSignUpForm/EmailSignUpForm.types';

import {
  submitEmailSignUp,
  submitEmailSignUpFollowUp
} from '@/modules/EmailSignUpModule/EmailSignUpModule.api';
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import { TransactionId } from '@/plugins/utm/SegmentElement';

export interface EmailSignUpModuleState extends TransactionId {
  IsSubmitted: boolean;
  IsFollowUpSubmitted: boolean;
  EmailSubmitted: string;
  HideSecondStep: boolean;
  FollowUpTransactionID: number;
}

export const SUBMIT_FORM = 'SUBMIT_FORM';
export const SUBMIT_FOLLOW_UP_FORM = 'SUBMIT_FOLLOW_UP_FORM';
export const SET_FORM_IS_SUBMITTED = 'SET_FORM_IS_SUBMITTED';
export const SET_FOLLOW_UP_FORM_IS_SUBMITTED = 'SET_FOLLOW_UP_FORM_IS_SUBMITTED';
export const SET_TRANSACTION_ID = 'SET_TRANSACTION_ID';
export const SET_FOLLOW_UP_TRANSACTION_ID = 'SET_FOLLOW_UP_TRANSACTION_ID';

const actions: ActionTree<EmailSignUpModuleState, RootState> = {
  async [SUBMIT_FORM]({ state, commit }, model: EmailSignUpUser) {
    //console.log('submitted', model);
    const result = await submitEmailSignUp(model);
    if (result.IsOk) {
      //console.log('from server', result);
      commit(SET_TRANSACTION_ID, result.Item.transaction_id);
      commit(SET_FORM_IS_SUBMITTED, model.Email);
    } else {
      //console.log('error', result);
    }
  },
  async [SUBMIT_FOLLOW_UP_FORM]({ state, commit }, model: EmailSignUpUser) {
    //console.log('follow up submitted', model);
    model.Email = state.EmailSubmitted;
    const result = await submitEmailSignUpFollowUp(model);
    if (result.IsOk) {
      //console.log('from server', result);
      commit(SET_FOLLOW_UP_TRANSACTION_ID, result.Item.transaction_id)
      commit(SET_FOLLOW_UP_FORM_IS_SUBMITTED);
    } else {
      //console.log('error', result);
    }
  },
};

const mutations: MutationTree<EmailSignUpModuleState> = {
  [SET_FORM_IS_SUBMITTED](state, email: string) {
    state.IsSubmitted = true;
    state.EmailSubmitted = email;
    if (!state.HideSecondStep) Vue.nextTick(() => VueScrollTo.scrollTo('#app'));
  },
  [SET_FOLLOW_UP_FORM_IS_SUBMITTED](state) {
    state.IsFollowUpSubmitted = true;
  },
  [SET_TRANSACTION_ID](state, id: number) {
    state.transaction_id = id;
  },
  [SET_FOLLOW_UP_TRANSACTION_ID](state, id: number) {
    state.FollowUpTransactionID = id;
  },
};

const registerModule = registerSubModule<EmailSignUpModuleState, EmailSignUpViewModel>(
  (data: EmailSignUpViewModel) => {
    return {
      namespaced: true,
      state: {
        IsSubmitted: false,
        IsFollowUpSubmitted: false,
        EmailSubmitted: '',
        HideSecondStep: data.HideSecondStep,
        transaction_id: 0,
        FollowUpTransactionID: 0,
      },
      actions,
      mutations,
    } as Module<EmailSignUpModuleState, RootState>;
  },
  'EmailSignUpModule',
);

export default (store: Store<RootState>, data: EmailSignUpViewModel) => {
  return registerModule(store, data)
}
