import { Component, Vue } from 'vue-property-decorator';
import { TitleHeader } from '@/common/interfaces/TitleHeader';
import Arrow from '@/assets/icons/arrow.svg?inline';

const TitleHeaderProps = Vue.extend({
  props: {
    p: Object as () => TitleHeader,
    tag: { type: String, default: 'h1' }
  }
});

@Component({
  components: {
    Arrow,
  },
})
export default class TitleHeaderComponent extends TitleHeaderProps {}
