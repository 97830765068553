import BaseValidation from '@/forms/Validations/BaseValidation';

import { names } from './EmailSignUpFollowUpForm.types';

const checkboxValidation = new BaseValidation([]);
checkboxValidation.addModel(names.TopTravelInterests, []);

const dropdownValidation = new BaseValidation([]);
dropdownValidation.addModel(names.HowDidYouHearAboutUs, '');
dropdownValidation.addModel(names.WhatIsYourAgeGroup, '');
dropdownValidation.addModel(names.GenerallyDoYouTravel, '');

export default () => {
  const validations = [
    dropdownValidation,
    checkboxValidation
  ];

  return {
    validations: BaseValidation.getRules(validations),
    messages: BaseValidation.getMessages(validations),
    model: BaseValidation.getModel(validations),
  };
};
