import TitleHeader from '@/components/TitleHeader/TitleHeader.vue';

import StoreModuleMixin from '@/mixins/StoreModuleMixin';
import EmailSignUpFollowUpForm
  from '@/modules/EmailSignUpModule/EmailSignUpFollowUpForm/EmailSignUpFollowUpForm.vue';
import {EmailSignUpUser} from '@/modules/EmailSignUpModule/EmailSignUpForm/EmailSignUpForm.types';

import EmailSignUpForm from '@/modules/EmailSignUpModule/EmailSignUpForm/EmailSignUpForm.vue';
import {
  EmailSignUpModuleState,
  SUBMIT_FOLLOW_UP_FORM,
  SUBMIT_FORM
} from '@/modules/EmailSignUpModule/EmailSignUpModule.store';
import {EmailSignUpViewModel} from '@/modules/EmailSignUpModule/EmailSignUpModule.types';
import { GET_MODULES } from '@/store/modules/modulesLoadModule';
import { PAGE_META, PageMeta } from '@/store/modules/metadataModule';

import {getComponentProps} from '@/utils/vueUtils';
import {Component, Mixins, Watch} from 'vue-property-decorator';
import { SegmentElement, SegmentTrackEvents } from '@/plugins/utm/SegmentElement';
import { EmailSignUpUser as EmailSignUpFollowUpUser } from './EmailSignUpFollowUpForm/EmailSignUpFollowUpForm.types';
import { getFormPayload, isSSR, scriptToHead } from '@/utils/commonUtils';
import Cookies from 'js-cookie';
import RecaptchaMixin from '@/forms/FormElements/Mixins/RecaptchaMixin';
@Component({
  components: {
    EmailSignUpForm,
    EmailSignUpFollowUpForm,
    TitleHeader,
  },
  props: getComponentProps<EmailSignUpViewModel>({
    Title: 'Email Sign Up',
    Subtitle: "Get inbox inspiration you'll look forward to: photos, videos, live webinar invites, special offers, and updates. Fields in bold with an asterisk (*) are required.",
    CTAButtonText: 'Send me offers',
    ThankYou: '<p>Thank you</p>',
    TermsAndConditionsLink: '/terms-conditions',
    PrivacyPolicyLink: '/privacy-policy',
    BottomText: 'I authorize Lindblad Expeditions to email me; however, I am able to unsubscribe at any time. For more details, see our <a "href="#" target="_blank">Privacy Policy</a>.',
  }),
})
export default class EmailSignUpModule extends Mixins(StoreModuleMixin, RecaptchaMixin) {
  state!: EmailSignUpModuleState;
  postForm: (model: EmailSignUpUser) => void = this.getAction(SUBMIT_FORM);
  postFollowUpSubmitted: (model: EmailSignUpFollowUpUser) => void = this.getAction(SUBMIT_FOLLOW_UP_FORM);

  itinerariesLink: string = '';
  destinationsLink: string = '';

  get titleHeaderData() {
    const bookingUrl = this.$store.getters[PAGE_META].BookingUrl;
    const currentLink = this.$store.state.PageUri.BaseUrl;
    const urlbookingUrl = new URL(window.location.origin + bookingUrl);
    const destItem = urlbookingUrl.searchParams.get('destinations.name');

    return this.state.IsSubmitted
      ? {
        Title: "Thank you for subscribing",
        Text: "You will get an email verifying your subscription.",
        Link: {},
        Links: [
          {
            Title: "View " + (destItem || "All") + " Itineraries",
            Link: destItem ? currentLink + "#itineraries" : (this.itinerariesLink || "/itineraries")
          },
          {
            Title: "View All Destinations",
            Link: this.destinationsLink || "/destinations/"
          },
        ],
        Center: true,
      }
      :
      {
        Title: this.$props.p.Title,
        Text: this.$props.p.Subtitle,
        Link: {},
        Center: false,
      };
  }

  submitPayload: any = {};
  submitIdentifyPayload = {};

  followUpPayload = {};
  followUpIdentifyPayload = {};

  @Watch('state.IsSubmitted')
  isSubmittedChanged() {
    this.submitPayload.transaction_id = this.state.transaction_id
    SegmentElement.sendEvent(SegmentTrackEvents.formSubmitted, this.submitPayload)
  }

  async submitForm(model) {
    model.RecaptchaResponse = await this.getRecaptchaToken()
    SegmentElement.sendEvent('formSubmissionAttempted', this.submitPayload)
    const referring_domain = Cookies.get('referring_domain')
    this.submitIdentifyPayload = {
      first_name: model.FirstName,
      last_name: model.LastName,
      email: model.Email,
    }
    const serverPayload = {
      ...this.submitPayload,
      ...this.submitIdentifyPayload,
      locale: SegmentElement.getLocale(),
      ...(!SegmentElement.getIsLanding() && { previous_page_name: SegmentElement.getPrevPageName() }),
      page_name: SegmentElement.getPageName(),
      ...(referring_domain && { referring_domain }),
      ...(Cookies.get('_fbc') && { fbclid: Cookies.get('_fbc').split('.')[3] }),
      ...(Cookies.get('email_md5') && { email_md5: Cookies.get('email_md5') }),
      ...(Cookies.get('_uetmsclkid') && { mscklid: Cookies.get('_uetmsclkid').split('_uet')[1] }),
      ...(Cookies.get('_gcl_aw') && { gclid: Cookies.get('_gcl_aw').split('.')[2] }),
    }
    const res = {
      ...model,
      AnalyticsPayload: JSON.stringify(serverPayload)
    }
    this.postForm(res);
  }

  get IsSubscribed() {
    const HideSecondStep = this.state?.HideSecondStep;
    if (isSSR() && HideSecondStep) return true;
    if (isSSR() && !HideSecondStep) return false;
    return Cookies.get('newsletterSubscribed') && HideSecondStep;
  }

  @Watch('state.IsFollowUpSubmitted')
  isFollowUpSubmittedChanged() {
    const fullPayload = {
      ...this.followUpIdentifyPayload,
      ...this.followUpPayload,
      transaction_id: this.state.FollowUpTransactionID,
    }
    SegmentElement.sendEvent(SegmentTrackEvents.formSubmitted, fullPayload)
  }
  
  async followUpSubmitted(model: EmailSignUpFollowUpUser) {
    model.RecaptchaResponse = await this.getRecaptchaToken()
    SegmentElement.sendEvent('formSubmissionAttempted', this.followUpPayload)
    this.followUpIdentifyPayload = {
      referral_source: model.ReferralSource,
      topic_interests: model.TopTravelInterests,
      age_group: model.AgeGroup,
      travel_preference: model.TravelPreference,
    }
    const fullPayload = {
      ...this.followUpIdentifyPayload,
      ...this.followUpPayload
    }

    const serverPayload = {
      ...fullPayload,
      ...(!SegmentElement.getIsLanding() && { previous_page_name: SegmentElement.getPrevPageName() }),
      locale: SegmentElement.getLocale(),
      page_name: SegmentElement.getPageName(),
      email: (this.submitIdentifyPayload as any).email,
      first_name: (this.submitIdentifyPayload as any).first_name,
      last_name: (this.submitIdentifyPayload as any).last_name,
      ...(Cookies.get('_fbc') && { fbclid: Cookies.get('_fbc').split('.')[3] }),
      ...(Cookies.get('email_md5') && { email_md5: Cookies.get('email_md5') }),
      ...(Cookies.get('_uetmsclkid') && { mscklid: Cookies.get('_uetmsclkid').split('_uet')[1] }),
      ...(Cookies.get('_gcl_aw') && { gclid: Cookies.get('_gcl_aw').split('.')[2] }),
    }

    const res = {
      ...model,
      AnalyticsPayload: JSON.stringify(serverPayload),
    }
    this.postFollowUpSubmitted(res)
  }

  emailSignUpFollowUpFormMounted() {
    SegmentElement.sendEvent('formViewed', {...this.followUpPayload, nonInteraction: 1})
  }

  created() {
    this.siteKey = '6Lelwv4eAAAAAHavTU2624NBQb8SDFnhmZzpvgZH';
  }

  mounted() {
    const navLinksData = this.$store.getters[GET_MODULES][0].Data.Items ? this.$store.getters[GET_MODULES][0].Data.Items[0].Items[0].Link : null // Take actual links from nav state
    if (navLinksData) {
      this.itinerariesLink = navLinksData.find(item => item.Title.toLowerCase().includes('itineraries'))?.Link;
      this.destinationsLink = navLinksData.find(item => item.Title.toLowerCase().includes('destinations'))?.Link;
    }
    this.submitPayload = getFormPayload('EmailSignUp', this.$attrs.contentLinkId);
    this.followUpPayload = getFormPayload('EmailSignUpInterestsForm', this.$attrs.contentLinkId + '-2');
    if (Cookies.get('newsletterSubscribed') && this.state.HideSecondStep) {
      this.hideCaptcha();
    } else {
      SegmentElement.sendEvent('formViewed', {...this.submitPayload, nonInteraction: 1})
    }
  }
}
